<template>
  <v-dialog v-model="visible" width="700px" transition="dialog-top-transition">
    <!-- Karte mit Toolbar und fixierten Tabs -->
    <v-card :theme="isDarkMode ? 'dark' : 'light'">
      <!-- Toolbar mit Tabs und Close-Button -->
      <v-toolbar density="compact">
        <!-- Tabs -->
        <v-tabs v-model="chart">
          <v-tab value="compareTeams">Teamvergleich</v-tab>
          <v-tab v-if="false" value="treeMap">Torverteilung</v-tab>
        </v-tabs>
        <v-spacer></v-spacer>
        <!-- Close Button -->
        <v-btn icon @click="close" class="ml-2">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- Dynamischer Inhalt -->
      <div v-if="chart === 'compareTeams'" style="padding: 10px;">
        <v-btn size="small" :prepend-icon="allSelected ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'"
          variant="outlined" @click="toggleAllLegends">
          {{ allSelected ? 'Alle abwählen' : 'Alle auswählen' }}
        </v-btn>
      </div>

      <v-chart ref="echartsRef" :option="chartOption" :theme="isDarkMode ? 'dark' : 'light'"
        style="height: 400px;"></v-chart>
    </v-card>
  </v-dialog>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/theme/dark";

export default {
  props: {
    players: Array,
    chartType: String,
    onClose: Function, // Hinzufügen des onClose-Props
  },
  name: "BillboardModal",
  components: {
    "v-chart": ECharts,
  },
  data() {
    return {
      chart: this.chartType,
      visible: false,
      chartOption: this.chooseOptions(this.players, this.chartType),
      allSelected: true,
    };
  },
  watch: {
    chart(value) {
      this.chartOption = this.chooseOptions(this.players, value);
    },
    visible(value) {
      if (value) {
        this.$nextTick(() => {
          this.chartOption = this.chooseOptions(this.players, this.chartType);
        });
      } else {
        this.close();
      }
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.smAndDown;
    },
    isDarkMode() {
      return this.$store?.state?.isDarkMode ?? false;
    }
  },
  methods: {
    toggleAllLegends() {
      this.allSelected = !this.allSelected; // Zustand umschalten
      this.toggleLegendSelection(this.allSelected);
    },
    toggleLegendSelection(selectAll) {
      const selection = {};
      this.chartOption.legend.data.forEach((name) => {
        selection[name] = selectAll;
      });
      this.chartOption.legend.selected = selection;
      this.updateChart();
    },
    updateChart() {
      if (this.$refs.echartsRef) {
        this.$refs.echartsRef.setOption(
          {
            legend: {
              selected: this.chartOption.legend.selected,
            },
          },
          false
        );
      }
    },
    chooseOptions(players, chartType) {
      switch (chartType) {
        case "compareTeams":
          return this.options_compareTeams(players);
        case "treeMap":
          return this.options_treemap(players);
        default:
          return {}; // Standard-Optionen oder Fehlermeldung
      }
    },
    options_compareTeams(players) {
      let gruppierteDaten = this.data_compareTeams(players);

      // Maximalwerte für die Achsen definieren
      let maxWerte = {
        Tore: Math.max(...Object.values(gruppierteDaten).map(d => d.Tore)),
        Feldtore: Math.max(...Object.values(gruppierteDaten).map(d => d.Feldtore)),
        Siebenmeter_Tore: Math.max(...Object.values(gruppierteDaten).map(d => d.Siebenmeter_Tore)),
        Siebenmeter_geworfen: Math.max(...Object.values(gruppierteDaten).map(d => d.Siebenmeter_geworfen)),
        Zeitstrafen: Math.max(...Object.values(gruppierteDaten).map(d => d.Zeitstrafen)),
        Siebenmeter_percentage: 100
      };

      return {
        tooltip: {
        },
        legend: {
          data: Object.keys(gruppierteDaten),
          top: 5, // Legende oben platzieren mit etwas Abstand zum Rand
          orient: 'horizontal', // Legende horizontal ausrichten
          left: 5, // Legende im Zentrum ausrichten
          itemWidth: 10, // Breite der Legenden-Icons verkleinern
          itemHeight: 10, // Höhe der Legenden-Icons verkleinern
          // padding: [5, 5, 5, 5], // Padding für die Legende hinzufügen
          formatter: (name) => {
            // Kürze die Namen nur, wenn isMobile true zurückgibt.
            return this.isMobile && name.length > 10 ? name.slice(0, 10) + '...' : name;
          },
        },
        radar: {
          indicator: [
            { name: 'Tore', max: maxWerte.Tore, color: this.isDarkMode ? 'white' : 'black' },
            { name: 'Feldtore', max: maxWerte.Feldtore, color: this.isDarkMode ? 'white' : 'black' },
            { name: '7m Tore', max: maxWerte.Siebenmeter_Tore, color: this.isDarkMode ? 'white' : 'black' },
            { name: '7m gew.', max: maxWerte.Siebenmeter_geworfen, color: this.isDarkMode ? 'white' : 'black' },
            { name: '2 Min.', max: maxWerte.Zeitstrafen, color: this.isDarkMode ? 'white' : 'black' },
            { name: '7m %', max: maxWerte.Siebenmeter_percentage, color: this.isDarkMode ? 'white' : 'black' }

          ],
          radius: this.isMobile ? '48%' : '60%',
          center: ['50%', '60%'], // Chart nach unten verschieben
        },
        series: [{
          name: 'Mannschaftsstatistiken',
          type: 'radar',
          data: Object.keys(gruppierteDaten).map(key => ({
            value: [
              gruppierteDaten[key].Tore,
              gruppierteDaten[key].Feldtore,
              gruppierteDaten[key].Siebenmeter_Tore,
              gruppierteDaten[key].Siebenmeter_geworfen,
              gruppierteDaten[key].Zeitstrafen,
              gruppierteDaten[key].Siebenmeter_percentage
            ],
            name: key
          })),
        }],
        backgroundColor: 'transparent',
      };
    },
    data_compareTeams(players) {
      let gruppierteDaten = {};
      players.forEach(player => {
        if (!gruppierteDaten[player.Mannschaft]) {
          gruppierteDaten[player.Mannschaft] = {
            Tore: 0,
            Feldtore: 0,
            Siebenmeter_Tore: 0,
            Siebenmeter_geworfen: 0,
            Zeitstrafen: 0,
            Siebenmeter_percentage: 0
          };
        }

        gruppierteDaten[player.Mannschaft].Tore += player.Tore;
        gruppierteDaten[player.Mannschaft].Feldtore += player.Feldtore;
        gruppierteDaten[player.Mannschaft].Siebenmeter_Tore += player.Siebenmeter_Tore;
        gruppierteDaten[player.Mannschaft].Siebenmeter_geworfen += player.Siebenmeter_geworfen;
        gruppierteDaten[player.Mannschaft].Zeitstrafen += player.Zeitstrafen;
        gruppierteDaten[player.Mannschaft].Siebenmeter_percentage = ((gruppierteDaten[player.Mannschaft].Siebenmeter_Tore / gruppierteDaten[player.Mannschaft].Siebenmeter_geworfen) * 100).toFixed(2);
      });
      return gruppierteDaten;
    },
    options_treemap(players) {
      let mannschaftsDaten = this.data_treemap(players);

      return {
        animation: false,
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c}'
        },
        legend: {
          color: 'black',
        },
        series: [{
          name: 'Tore',
          type: 'treemap',
          visibleMin: 10,
          data: mannschaftsDaten,
          leafDepth: 1, // Tiefe, bis zu der die Baumstruktur initial angezeigt wird
          levels: [
            {
              itemStyle: {
                borderColor: '#555',
                borderWidth: 4,
                gapWidth: 4
              }
            },
            {
              colorSaturation: [0.3, 0.6],
              itemStyle: {
                borderColorSaturation: 0.7,
                gapWidth: 2,
                borderWidth: 2
              }
            }
          ],
          label: {
            color: 'black',
          }
        }]
      };
    },
    data_treemap(players) {
      let mannschaftsDaten = {};
      // Aggregieren der Gesamttore je Mannschaft
      players.forEach(player => {
        if (!mannschaftsDaten[player.Mannschaft]) {
          mannschaftsDaten[player.Mannschaft] = { name: player.Mannschaft, value: 0, children: [] };
        }
        mannschaftsDaten[player.Mannschaft].value += player.Tore;

        let spielerDaten = mannschaftsDaten[player.Mannschaft].children.find(child => child.display_name === player.display_name);
        if (!spielerDaten) {
          spielerDaten = { name: player.display_name, value: player.Tore, children: [] };
          mannschaftsDaten[player.Mannschaft].children.push(spielerDaten);
        }

        spielerDaten.children.push({ name: '7m Tore', value: player.Siebenmeter_Tore });
        spielerDaten.children.push({ name: 'Feldtore', value: player.Feldtore });
      });

      return Object.values(mannschaftsDaten);
    },
    close() {
      this.visible = false;
      this.onClose?.(); // Rufen Sie die übergebene onClose-Funktion auf
    },
  },
  mounted() {
    this.visible = true;
  },
};
</script>

<style>
@media (max-width: 600px) {
  .v-chart {
    font-size: 10px;
    /* Schriftgröße für kleinere Bildschirme */
  }
}
</style>
