<template>
    <v-dialog v-model="visible" max-width="700px" transition="dialog-top-transition">
        <!-- Karte mit Toolbar und fixierten Tabs -->
        <v-card class="d-flex flex-column" max-height="90vh" :theme="isDarkMode ? 'dark' : 'light'">
            <!-- Toolbar mit Tabs und Close-Button -->
            <v-toolbar density="compact">
                <v-tabs v-model="view">
                    <v-tab value="table">Tabelle</v-tab>
                    <v-tab value="matches">Spielplan</v-tab>
                </v-tabs>
                <v-spacer></v-spacer>
                <!-- Close Button -->
                <v-btn icon @click="closeModal" class="ml-2">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <!-- Dynamischer, scrollbar oder automatischer Inhalt -->
            <v-container class="pa-4 overflow-y-auto" :style="{ maxHeight: autoHeight ? 'auto' : 'calc(90vh - 48px)' }">
                <div v-if="view === 'table'" id="handball-tabelle"></div>
                <div v-if="view === 'matches'" id="handball-spielplan"></div>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        onClose: Function,
        tournamentId: String
    },
    name: 'TableModal',
    data() {
        return {
            visible: false,
            view: "table",
            scriptLoaded: false,
            autoHeight: false // Dynamische Höhenregelung
        };
    },
    watch: {
        visible(value) {
            if (!value) {
                this.onClose?.();
            } else {
                this.updateHeight(); // Höhe bei Sichtbarkeit aktualisieren
            }
        },
        view(newView) {
            this.$nextTick(() => {
                setTimeout(() => {
                    if (newView === 'table' && this.scriptLoaded) {
                        this.initializeTableWidget();
                    } else if (newView === 'matches' && this.scriptLoaded) {
                        this.initializeMatchWidget();
                    }
                    this.updateHeight(); // Höhe nach Tab-Wechsel anpassen
                }, 0);
            });
        }
    },
    computed: {
        isDarkMode() {
            return this.$store?.state?.isDarkMode ?? false;
        }
    },
    methods: {
        loadWidgetScript() {
            if (!document.querySelector('script[src="https://www.handball.net/widgets/embed/v1.js"]')) {
                const script = document.createElement('script');
                script.src = 'https://www.handball.net/widgets/embed/v1.js';
                script.async = true;
                script.onload = () => {
                    this.scriptLoaded = true;
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.initializeTableWidget();
                            this.initializeMatchWidget();
                            this.updateHeight(); // Höhe nach dem ersten Laden anpassen
                        }, 0);
                    });
                    this.visible = true; // Modal öffnen
                };
                document.body.appendChild(script);
            } else {
                this.scriptLoaded = true;
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.initializeTableWidget();
                        this.initializeMatchWidget();
                        this.updateHeight(); // Höhe nach dem ersten Laden anpassen
                    }, 0);
                });
                this.visible = true; // Modal direkt öffnen
            }
        },
        initializeTableWidget() {
            if (window._hb && this.tournamentId) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        const container = document.getElementById('handball-tabelle');
                        if (container) {
                            container.innerHTML = ''; // Verhindert doppelte Einbindungen
                            window._hb({
                                widget: 'tabelle',
                                tournamentId: this.tournamentId,
                                container: 'handball-tabelle'
                            });
                        }
                    }, 0);
                });
            }
        },
        initializeMatchWidget() {
            if (window._hb && this.tournamentId) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        const container = document.getElementById('handball-spielplan');
                        if (container) {
                            container.innerHTML = ''; // Verhindert doppelte Einbindungen
                            window._hb({
                                widget: 'spielplan',
                                tournamentId: this.tournamentId,
                                container: 'handball-spielplan'
                            });

                            // Funktion zur Einfärbung der Scores
                            const colorizeScores = () => {
                                const items = container.querySelectorAll(".hb-embed__schedule-list-item-starts-at");

                                if (items.length === 0) {
                                    setTimeout(colorizeScores, 50);
                                    return;
                                }
                                items.forEach((item) => {
                                    const scores = item.querySelectorAll(".hb-embed__schedule-list-item-score");

                                    if (scores.length === 2) {
                                        const score1 = parseInt(scores[0].innerText, 10);
                                        const score2 = parseInt(scores[1].innerText, 10);

                                        if (score1 > score2) {
                                            scores[0].style.backgroundColor = "darkgreen";
                                            scores[1].style.backgroundColor = "#c72c2c";
                                        } else if (score1 < score2) {
                                            scores[0].style.backgroundColor = "#c72c2c";
                                            scores[1].style.backgroundColor = "darkgreen";
                                        } else {
                                            scores[0].style.backgroundColor = "#bd7d06";
                                            scores[1].style.backgroundColor = "#bd7d06";
                                        }
                                    }
                                });
                            };

                            // Starte die Einfärbung nach einer kurzen Verzögerung
                            setTimeout(colorizeScores, 50);
                        }
                    }, 0);
                });
            }
        },
        updateHeight() {
            const tableContainer = document.getElementById('handball-tabelle');
            const matchContainer = document.getElementById('handball-spielplan');
            const activeContainer = this.view === 'table' ? tableContainer : matchContainer;

            if (activeContainer) {
                this.$nextTick(() => {
                    const contentHeight = activeContainer.offsetHeight;
                    this.autoHeight = contentHeight < window.innerHeight * 0.9; // Automatische Höhe, wenn kleiner als 90vh
                });
            }
        },
        closeModal() {
            this.visible = false;
            this.onClose?.();
        }
    },
    mounted() {
        this.loadWidgetScript();
    },
    beforeUnmount() {
        const tableContainer = document.getElementById('handball-tabelle');
        if (tableContainer) {
            tableContainer.innerHTML = '';
        }

        const matchContainer = document.getElementById('handball-spielplan');
        if (matchContainer) {
            matchContainer.innerHTML = '';
        }
    }
};
</script>

<style>
/* Optional: Anpassungen für mobile Darstellung */
@media (max-width: 600px) {

    #handball-tabelle,
    #handball-spielplan {
        transform: scale(0.8);
        transform-origin: top left;
        width: 125%;
    }
}

.v-card.v-theme--dark {
    .hb-embed {
        a {
            color: white !important;
        }

        color: white !important;
    }
}

.hb-embed__title-container {
    display: none !important;
}

#handball-spielplan {
    .hb-embed__schedule-list-item {
        a {
            display: none;
        }

        .hb-embed__schedule-list-item-content {
            padding: 0;
            display: flex;
        }

        .hb-embed__schedule-list-item-score {
            padding: 0.25em 0.5em;
        }
    }

    .hb-embed__schedule-list-grouped-item {
        margin-bottom: 0 !important;
    }
}
</style>
